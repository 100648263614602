export const adlist = [
    '0xC8EDC8718a8cA2A4FcC9833fe72c5ba8A480b1ce',
    '0xD78f363d130E23286560198d012233ea83D4724C',
    '0x3111BdF9de04164caa452C035AE28cf43fc2a691',
    '0xf52D3Db3DA8f40Dee042B23756D9248F81B66875',
    '0xBacA5C11d630abfC6438f73e80202282695aFE1d',
    '0xC521DB3cfBD657cbcd497C1bC230cFa2A0D2E2b6',
    '0xf1009695fdeb17705ec6d67c33649caaf9476521',
    '0x22c43cc90072283769f72f7c5ff8ab3b5c59dcd5',

    '0x4aec2a6ef11bb9011b72b43f3d7d5c0e555c3490',
    '0x341dff03997ea23fa98c1117a8cbedd81ee7d2ae',
    '0x380e60d784cfbb4a9c2e0faee7abf78a697794dd',
    '0xcbcab9b3ccd9e6c7458cc251848669bb1abfdee6',
    '0x55b5c05ae1ff98e650e4426b84baadd3d09180e5',
    '0x921fa256171ae2b6e6b408d56f82aff660c16b76',
    '0x7d05dd49f7611fa01e2762ad40699c6a7c8331fd',
    '0x972c8a4eb8cdc40cab822e6257cd0ce2be32d435',
    '0xd5179cad43c6fee4e33e151dde76049881c95307',
    '0xbd02605a15fd982994441d0a90494c244af0fe61',
    '0xacb7139accbd4ae28f8a3f438ce3a499649c775b',
    '0xa5c05133b8f3dc5f40c8e03c2f6fcb7f92789a5b',
    '0xacd2e21d5b79d3bbbfc33847981de6694ab3480f',
    '0xcaa8137d553506ac3d2f79ddaa34753fa54d6f52',
    '0x6ddba320e4b9c1acaf570e7264318b86cde7bdce',
    '0x3bb5f3ebf7f3b9b10c84390b3e4429bb07361b54',
    '0x950072f272dd7eda6e96c493a25a7ef8ff96fb1a',
    '0xe89af63f276aab23efbc434d9270605429951937',
    '0x50a1f33d6ccc1ae543cfe1e594cefe89b3a2a504',
    '0x6011a4645daac6527f84f3f0d97bfb3b66496ffc',
    '0x4716fa207f0ae5eecc810a8a8ed1821a753263e9',
    '0xd7c3ab1f6fb9b310983cdd3f62229fe29ce1fd1d',
    '0xd0d7957e17c04679b9819ee58de1409b4527dac8',
    '0x86cfd5a7c30c67c761b1c94920f1c2b39dfc89bf',
    '0x7cb8ae57613dc145427bf285abfeda4a28c3bf20',
    '0x0017d1b82179f8ab13d338ad444e3de294211a0f',
    '0xab8db82b07f79129c10b3a73998fbd4caaa0515e',
    '0xc81a31ca022c1230e42b30086c19a0f4be86b664',
    '0x867ce58124475a47a48f9174de8d2fb7f5645904',
    '0xe217cb589a7291e02e0de2e64abe66f9489d9b39',
    '0x09efcdce6945145c23df4267d8135df5b0c2bf3d',
    '0xafd9c93125d46bb36babef82d1f01e25dba71532',
    '0xf8c3195e73e4b103ffdcc2bac95a8df4b6743bab',
    '0x352e8059dc061a47169450cf460bb9bab8eb1407',
    '0xebde599aceb82e1bc09fd4d57c871d4d322c2e1a',
    '0x0a83f2fedf6c455cdfdcd02a47cc8c0cac2f768f',
    '0xd79af5c216c981f3c68b021e6e88ba3df6a0cc88',
    '0xa504c00a84b12111b847478ec93ae9fdfdd9bcad',
    '0x4da12aade329c5e5e0904a5481edc4ed2540c92f',
    '0xfe3fecdf9c5a5362a957447948b22ac58f7a9df0',
    '0x9927a7180e8a1568bde63bc0296ceb303a298e45',
    '0xbec0ecff61c3b65a3219710afedd3b83c8f4d423',
    '0xc85c587457b162511b73dcbd3a9851d73e2f2e05',
    '0xd79595fb9540badcc7a03a86e1f47e4acc520bfa',
    '0xf85d9b3c81766dec5c9979a36974c1affcf4c677',
    '0x67e53773a082c9a9bf3d03d125abd30177b0c886',




    '0xEc6B502Ab523df5922030c6673FcA61AbBd03C53',

    '0x534d9c457a3d64eeee25db086fc23e4b3a94bb2b',
    '0xd28e202aa4015b35aed39907762d5634e4c922fd',
    '0xa06d9e0c5ad6c30c28fdbede4b66578992757c42',
    '0xeff799301890225f6d3f29a89da5c2586d8087e0',
    '0x00fe9de2b933db62b98372d1318a8bb24dfbab85',
    '0x862c132f0daa105b9421f0dbd0c480f8e615d226',
    '0xb13c9ef6342ec4e3f7ae8bd5e0d535b6c86df1e9',
    '0x9b32cb9f31cc428a6dbcbf859078ba6fd33a749b',
    '0x491ece3cc5c6e9a21258e5ae2d251993521c9637',
    '0xb221ee7be8d1fd0a555aac15ec017d82c28d1a6c',
    '0x081b1138dd2f7a20a223e8ba93e670488ddfff06',
    '0x68b3a324365f000f33aca25c446a7897f11fc10f',
    '0xcba678622c94eb128389e2f829c7cbaefb1c6eb9',
    '0x1779d215b34f0dcfe66a3feac739242ee7e0e38e',
    '0xc7ff04d2ab709c6cd1115471b8bdfb709b343880',
    '0xe30e7c181237db15b235928d681856f05281e4ff',
    '0x930ba07d1229db0758f7222997e99fb29bd25bd5',
    '0xf924518ce1c380e808591b184d6c3616a8e223de',
    '0x5410a04212edfed653630e3a6c349b1c96f0a498',
    '0xe28a45651fb1a6bb58ea5da0bbae52424b02a57d',
    '0x69cd7d15d7d8f692aa5faec878037d841f2cac77',
    '0x7a3acb0ff1ffa9a6c114613920829bed59c187f3',
    '0x5e029589a1341ba352204739e5a6b13795002cef',
    '0xf894b25d1cc552078d01fdfdfb25a8327c9546fe',
    '0xea53327c1edf3f48430095f26db4c8186bd8d487',
    '0x7545d3c4bbc43ec4bcd9a9b20541e9df4dd334ff',
    '0x9975fb965d79b98262701e212da7f10a920463e9',
    '0x923fc6b41aea5a96a2c6cc36777f713cd001436c',
    '0x13357f754ff86fdba00a8a223399b59c4b891f59',
    '0x1d9266dcba500a0410093e126adc32c8052dd37f',
    '0xb63a11f3bad81fdc4d575cfa4133f3c3571bdaa4',
    '0xaef89054ba4ec328deb554b965c85475b2556282',
    '0x0ee30f0bf4318887ec08984222e38a361499517a',
    '0x7f75bc57500fa977dc8c9ca8c75b8ab648378a0b',
    '0x026b9d6967bdfaa2ae5f0b6b638c422dc174de60',
    '0xb6fd65138252c87a677cde2c4c90f9aa0e03bba7',
    '0x39a7d0cb2a439f9ff38c9f3c76aba48eb60a93b1',
    '0x9cecf3238f763c06f214d9f7084e45d56051f986',
    '0x335c3ab2155d505f67d29961bc776b732ea6b4ea',
    '0x2cf6e64fed20ded52f0360dee0aef30404f5bb62',
    '0xabce5b25f87763f8dad7598691180078a0a83085',
    '0x39e9fcfff8d6d1d3e6465497d30a0cacd679327e',
    '0x7e68226919cb61fb926a580a1df16f8cf65aaa3c',
    '0x33aa2870dd058f45c90ffd7d09df50a1a8548210',
    '0x4e3c27aacad4b7f6f6a86a566e1e9efc3c34e460',
    '0xf2e0ba113e44e58db7266e94c052b8c6a3e0bc66',
    '0x258a6f7c1640ddffd0d4122ee4f7bfe993a45178',
    '0x67570cb5ccd445ca792ccf64d5deb6596f365d85',
    '0xf1fb92d0e20eead1ca631f582fe11052bd5854df',
    '0xa4a8405df53352df089881ceaee0f3752a4c3def',
    '0x848b315e0984be3fdf8ea1b621d4aa13be56aa92',
    '0x56b6165f75416a04ee1249a622d2941c9d310f6b',
    '0x9829136b438f593ea2c18672352dd452bd10a51b',
    '0xeb95cc5843c5c4df2ba55a2faa2f60d0524c1e05',
    '0xc9be38e8fd5dcf9431e311ae6d73d36f1e89bc10',
    '0x26b8a330faf111293b1f5ba7f250f0480dbe5de5',
    '0xaa73cb0c5e9453b9bc4ac41c7128ecde7edf4f2a',
    '0x3440db5cc47300a9ae137070b6e0e17f899fddfd',
    '0x4f9613faf505af25dc66d5b3cbb484020bf08fac',
    '0x0dcee9c5499e4e27139d46e255f7d5cfb690f1b9',
    '0x825ca6e548a8ab2dd0b5617ac02341222bb58013',
    '0xddb588ebac33578391af27255a44d682cb6b3e30',
    '0x4fdc17ec5518eb5cbc367f7033928ea59063fbad',
    '0xd7a76a3e0af668fa33f9b1276bea52d35c587e6d',
    '0x6ff688b47ab0c7fbe6c3d1ad766ed44100a5f025',
    '0xa28c17913a0aa522a7818fe981112c7156479875',
    '0xec18f30d8271854db41bf8293f3479abcbee7778',
    '0x7be4233da48bfdb39f2798bb41d1a8e2cc95a57d',
    '0x6df313a1efdbd91c0577b643e98c25de7d7ad47f',
    '0xa361bddf990ab8309c30c34a9de33fdc575dfe24',
    '0xdb724d7a1ad728d7b1f0420886feb346ce08fd4a',
    '0x3c5074291e5c78af85ca47a452fc5de269abe9e0',
    '0xf051c772dd717efad62c439cb95ca78652582f29',
    '0xa0584b7bc6099d7ceb4c0ce41ecbad70ae9a3b6c',
    '0x9dd1e17368eeceddebfcca62840cd88ae7ae1e1d',
    '0x9902983ee4590eecf7f0988e3c02b315da6478b8',
    '0x58d37880f2081be425d94ef4f8943cc748f033bb',
    '0xd5912d1dd105ea5025318baead05dd55a8855662',
    '0xb43d173bc0a07494783d0454bdebc0b12fce5cf1',
    '0x1c93f5d61e9812179189c9ce6d395830c83c8c0e',
    '0xd8c01922a6f5fc13af963cd89471251ed39d8a68',
    '0xf5fcd25e87a8e0ffdf9b855768ca80fc4043b3bd',
    '0x2e0b21e5a56d6b225dcd5e237f11d80d8d7f24fa',
    '0x346ad4ab0802925f11ca9a8bdb96239107d822f4',
    '0xf8c3195e73e4b103ffdcc2bac95a8df4b6743bab',
    '0xbc2415b5c896e77aacfec56cf8f677726337a7de',
    '0x352e8059dc061a47169450cf460bb9bab8eb1407',
    '0xebde599aceb82e1bc09fd4d57c871d4d322c2e1a',
    '0x0a83f2fedf6c455cdfdcd02a47cc8c0cac2f768f',
    '0xadc72753780414657d4de9ca7c9e55807ed6123b',
    '0xd79af5c216c981f3c68b021e6e88ba3df6a0cc88',
    '0x3294bfeea10be31e3f9c4b15a002b328a769e31f',
    '0xa504c00a84b12111b847478ec93ae9fdfdd9bcad',
    '0x4da12aade329c5e5e0904a5481edc4ed2540c92f',
    '0xfe3fecdf9c5a5362a957447948b22ac58f7a9df0',
    '0x9927a7180e8a1568bde63bc0296ceb303a298e45',
    '0xfc698c4b0fb2a7aaf62438a8365d8d5036e2d9f3',
    '0xa9755a6c66811dc7a6a01192ca753ac81daaa7f8',
    '0xfd05e5ef9d6c70c889edb02d9743122217808e82',
    '0xe8c842b5989dd99b68732f408f7fa5e015fde627',
    '0x163c41db821437d0f7025d76d94cbc7e3034cea6',
    '0xa72ec2427b3727ef30f9e37eb8b157fa3734d270',
    '0x6eb8594be447b1a1ccb4ac4d0e54e09d422139bd',
    '0x2841caf594f176a80795ea2c4153b5a39459950a',
    '0xf0e9f35cc06771a2557c66255403b557ebdde044',
    '0x292a59faede31a6869710e7273d5aab96638424c',
    '0xd049c5e86f0f7a19ab25936be1c3bc676d767476',
    '0xfd4032d37db53aecdc11d69181fc70a36e1533e6',
    '0x8c017867e3511c8e4c86e21acb4083e2439f61cc',
    '0x85c71f9c8c862e74aac1f15349e853b50d3989bc',
    '0x34565e9456a645c9676e81b42792fac4eb8b7046',
    '0xff501e8cca67a5058f19f503edc507327ed34aa9',
    '0x096538b13407c1ed360a25d4791abe6d0f3d8a15',
    '0x3dac43d274ebfa8255aa618f4db7961ef4b941c6',
    '0xbb9279a977ab8231343fe9492633ae4a502531b2',
    '0xd1a639f8b11c986a68351be50bdd79151230fae3',
    '0x553f426f9a606bad7833e391d6f731b9cca55696',
    '0x8d8b5527507dbfab082ea135c011a93bed66b32f',
    '0x072cf661924655976b436fcbabd33f67e281bda9',
    '0x9fca69fd4fdc6aa032b90d398758ffe42ed47150',
    '0x30591c8d45381f017e1e4cc60c6785801e2abb00',
    '0x416059a8e8981db4d57d8343e0f958ab854c3e25',
    '0x1450dd0039f64da90e38ce6b42f84ee0557be66a',
    '0xbc351f5790760b1cd22ea0efd794fecd5bd4643c',
    '0x7e3e2a07363aacd046de3fda4bec86b2159492ec',
    '0x342ccf6f47d630f09b05c1df744721b237f49bef',
    '0x70f84f8095085fecc9d2d184416cb66512ceb4c2',
    '0xc16437ab87224446dfc4dae5d978869804d71ee4',
    '0x8d895a0b818f0f6b15b105cf31d11d289caffe7f',
    '0xd4a1d8b5e68987c7355238ca780669d698e636b5',
    '0x817012c6391128df0fd6e7afcea9cd8afebe9009',
    '0x482062c47f872df5280a3dfebe0b6ab811a9d862',
    '0x33d50b64e3a7e6cfde7f8aca02a479223255b16e',
    '0x3c9f83664117d2e54fb6b665778f5641358bc30d',
    '0xaf7f2a69070695ed2b993fb6cc889750e96d5c83',
    '0x312069f784b9b2a82ca5226563b494a25ec193a6',
    '0x845e185b57b4a79a61b1351731b45907e7414adc',
    '0xe692287eb11ea9363c3889440a196039892afa65',
    '0x2118309c48d3d5e7625c4dd6d750f2ea6249c4e4',
    '0x971f360af96e76079ad7b9be57a299f648a6d288',
    '0x1d6c3164650b82272215da53213ef5fc38604b92',
    // "0x80D2E21D063c2dc82e3071F566EE214775711402"
]