export const m = {
  lqlj: "Confirm claim ",
  sqinfo: "To use the features of your personal account, please use Wallet authorization ",
  sqbtn: "Wallet Authorization ",
  sqload: "Authorizing..." ,
  dltt: "Sign in to your account ",
  dlinfo: "To use the features of your personal account, click on the Link Wallet ",
  dlbtn: "Linked Wallet ",
  bdtt: "Bind parent ",
  bdinfo: "Please enter the referrer's wallet address ",
  bdbtn: "Confirm binding ",
  bdload: "binding..." ,
  lqsg: "Claim successful ",
  lqsb: "Claim Failure ",
  day : "day ",
  shi:" Hour ",
  fen: "Fen!",
  miao:" Seconds ",
  fzcg:" Copy success ",
  fzsb:" Replication failure ",
  yhdj:" User Level ",
  bdcg:"Binding successful",
  notice:"ARCT is the world's first Web3 intelligent financial platform based on intelligent integrated service network construction, and the world's first financial service platform to achieve uninterrupted and automated operation." ,
  cyarc:" Participate in ARCT",
  ljyy:" Make an appointment now ",
  yysl:" Number of appointments ",
  xzsl:" Select quantity ",
  qr:" Confirm ",
  wdldx:" My Liquidity ",
  wdlp:" My LP value ",
  wdqydd:" My Contract Order ",
  jrgtxq:" Join ARCT for a new wave of DeFi!" ,
  qyc:" Contract Pool ",
  cbdz:" Holding address ",
  wdqy:" My Contract ",
  qysy:" Contract income ",
  qrlq:" Confirm collection ",
  lq:" Claim ",
  mx:" Details ",
  dqjl:" Community Rewards ",
  ny:" You have ",
  wcqbqr:" Complete wallet confirmation ",
  zyyyyd:" Note: Only one order can be booked within 24 hours ",
  dqsl:" Authorized Quantity ",
  yycg:" Participation success",
  yysb:" Participation failure",
  mysj:" No data ",
  sqjd:" Community node ",
  gsjd: "Consensus Node",
  dqjx:" Community Campaigning ",
  gsjdzsl: "Total number of Consensus nodes",
  gsjdydcsl:" Number of consensus nodes reached ",
  dqgsjdjc:" Current Consensus Node Award Pool ",
  wdjdjc:" My Node Award Pool ",
  wdcjd:" Node not reached ",
  ydcjd:" Node reached",
  djzlmm:" The total number of nodes is 51, and when the performance of the community reaches $2w, the nodes will become in order." ,
  jdmx:" Node Details",
  xh: "Serial number",
  dz:" Address ",
  sqjxjc:" Community Campaign Award Pool ",
  wdsqjxjc:" My Community Campaign Award Pool ",
  xqyjmm:" Community performance of 15,000 US dollars, the top 51 will be awarded the campaign pool." ,
  sqpm:" Community Ranking ",
  pm:" Ranking ",
  xqyj:" Community Performance ",
  zyj:" Total Performance ",
  dq:" Community ",
  wdyqlj:" My invitation link ",
  fz:" Copy ",
  qysqtj:" Contract Community Statistics ",
  zjtjhy:" Direct referral members ",
  xqyjzje:" Total amount of community performance ",
  sqyjzje:" Total amount of community performance ",
  wdfxmx:" My Share Details ",
  wx:" Invalid ",
  wdyydd:" My Reservation Order ",
  ywcdqydd:" Completed Contract Order ",
  dzf:" to be paid ",
  yyz:" Pending appointment ",
  dyz:" To be pledged ",
  qyje:" Contract amount ",
  yydjs:" Appointment Countdown ",
  zydjs:" Pledge Countdown ",
  zfdjs:"Pay Countdown",
  wcqydd:" Complete Contract Order ",
  ywc:" Completed ",
  ysh:" Redeemed ",
  zysl:" Quantity pledged ",
  jydjs:" Release Countdown ",
  shqydd:" Redemption Contract Order ",
  wcqyddxyzf:" Payment required to complete reservation order ",
  shcg:" Redemption successful ",
  shsb:" Redemption failed ",
  zfcg:" Payment success ",
  zfsb:" Payment failure ",
  wdfx:" My Share ",
  yxyh:" Valid User ",
  wxyh:" Invalid User ",
  grtr:" Personal Input ",
  sqyj:" Community Performance ",
  sfjd:" Node or not ",
  shi:" Yes ",
  fou:" No ",
  home:" Home ",
  wdsq:" My Community",
  jdjl:" Node Reward ",
  jxjl:" Campaign Rewards",
  tx:" Cash withdrawal ",
  jl:" Reward ",
  sl:" Quantity",
  swjryy:"Not yet joined the appointment, unable to share friends",
  wdtjdz:"My recommended address",
  qqdd: "Request wallet interaction",
  dkndqb: "Please open your wallet and confirm in the transaction activity to proceed with your order",
  zzclsw: "ARCT is engaged in blockchain transactions, please be patient and wait for transaction settlement",
  ddz: "Waiting",
  dqzdbmz:"The current address does not meet the invitation criteria! ",
  bklq:"Not available for collection！",
  nlcje:"Energy pool",
  wdnl:"Available energy value",
  nlcze:"My energy value",
  ytxnlz:"Energy value has been obtained",
  zwkf:"Tip: Not yet open!",


  xzsldj:" Select Computing Power Level ",
qbye:" wallet balance ",
njhdsle:" You will get 8 times the revenue of your computing power (2.6 times static, 5.4 times dynamic)." ,
ljcy:" Get Involved Now ",
jtsy:" Static Revenue ",
dlqsy:" Proceeds to be claimed ",
jqsy:" Earnings of the Day ",
ljsy:" Cumulative Earnings ",
dtsy:" Dynamic Returns ",
wdsl:" My computing Power ",
sul:'Power',
jrsf:" Released Today ",
jtsys:" Static residue ",
dtsys:" Dynamic Surplus ",

wddj:" My level ",
sfdjd:" Whether large node ",
sfxjd:" Small node or not ",
sffwzx:" Service Center or not ",
gryj:" Individual performance ",
yebz:"Insufficient balance",


}